<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        发展历程
      </div>
      <div class="topic_desc_en">
        DEVELOPMENT PATH
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <template v-for="(yItem,idx) in developmentHistoryPath">
        <div class="y_content" v-if="idx%2==0" :key="idx">
          <div class="y_topic">
            {{yItem.year}}
          </div>
          <div class="y_main">
            <div class="y_pic"></div>
            <div class="division_line"></div>
            <div class="m_content">
              <div class="m_item" v-for="(mItem,mIdx) in yItem.months" :key="mIdx">
                <div class="m_topic">
                  {{mItem.name}}
                </div>
                <div class="m_news" :class="[{
                  'm_news_i':mItem.isImportant
                }]" v-for="(nItem,nIdx) in mItem.value" :key="nIdx">
                  {{nItem}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="y_content" v-else :key="idx">
          <div class="y_topic">
            {{yItem.year}}
          </div>
          <div class="y_main">
            <div class="m_content">
              <div class="m_item m_item_left" v-for="(mItem,mIdx) in yItem.months" :key="mIdx">
                <div class="m_topic">
                  {{mItem.name}}
                </div>
                <div class="m_news" :class="[{
                  'm_news_i':mItem.isImportant
                }]" v-for="(nItem,nIdx) in mItem.value" :key="nIdx">
                  {{nItem}}
                </div>
              </div>
            </div>
            <div class="division_line"></div>
            <div class="y_pic"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {
      developmentHistoryPath:[]
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    articleInfo () {
      let lang = this.$i18n.locale;
      return this.infoData[lang] || "";
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.getDataDevelopmentHistoryPath();
    },
    // 获取数据 - 发展历程
    getDataDevelopmentHistoryPath(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=about&secondaryTitle=developmentHistoryPath`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"[]";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.developmentHistoryPath = configData;
        }else{
          _this.$notify({
            title: '失败',
            message: "发展历程数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getData();
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page {
  width: 100%;
  margin-top: 2.5rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .topic_panel {
    position: relative;
    margin-bottom: 3rem;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.5rem;
      color: #222222;
      line-height: 2.4rem;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.2rem;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 1.4rem;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    width: 100%;
    margin-bottom: 2rem;
    .y_content{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0.4rem;
      &:last-child{
        margin-bottom: 0;
      }
      .y_topic{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 1rem;
        color: #43496B;
        line-height: 1.4rem;
      }
      .y_main{
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        .y_pic{
          flex: 1;
          overflow: hidden;
          img{
            width: 100%;
          }
        }
        .division_line{
          width: 1px;
          // height: calc(100% - 10px);
          margin: 0 1rem;
          background: #D4D6DD;
          &::after{
            content: "";
            position: absolute;
            width: 1px;
            top: 0.4rem;
            bottom: 0.4rem;
            background: #D4D6DD;

          }
        }
        .m_content{
          flex: 1;
          overflow: hidden;
          margin: 0.8rem 0;
          display: flex;
          flex-direction: column;
          .m_item{
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;
            &.m_item_left{
              align-items: flex-end;
            }
            &:last-child{
              margin-bottom: 0;
            }
            .m_topic{
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 0.8rem;
              color: #0086D1;
              line-height: 1.2rem;
            }
            .m_news{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 0.8rem;
              color: #353B60;
              line-height: 1.2rem;
              &.m_news_i{
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 0.8rem;
                color: #353B60;
                line-height: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>